.App {
  text-align: center;
}
.LogoHeader{
  vertical-align: middle;
  text-align: center;
}
.noMargin{
  display: inline-block;
  line-height: normal;
  margin: 5px;
}
#logginModal{
  width: 35%;
}
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-40 { font-size: 40px; }

.altoParalax{
  height: 250px;
}
.caruselAlto{
  height: 10%;
}
.parallax{
  position:static;
}
.overflowTrue{
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0%;
  top: 0%;
  overflow: auto;
}
.overflow{
  overflow: auto;
}
.padding{
  padding:10px;
}

.anchoFotoProducto{
  width: 100%;
}
.marginProducto{
  margin: 10px;
  padding: 10px;

}
.finderRegistry{
  min-height: 50%;
  width: 90%;
}

@media only screen and (max-width: 992px) {
  #logginModal{
  width: 80%;
  }
 
  
  }
