.LogoHeader{
    vertical-align: middle;
  }
.parallax-container{
    height: 200px;
}  
.backGroundDashport{
    background-image: url('../../Configuracion/Imagenes/FotosFondo/fondogris_Dashport.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
}
.descriptionSquare{
    height:200px; 
    margin:2px; 
    padding:10px;
    background-color:#f44336;
    color: white;
}
.galeryMargin{
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}
.marginTitle{
    padding: 15px;
    margin: 15px;

}
.inscripcionesEventos{
    display: inline-block;
    height:120px; 
    width: auto;
    margin:2px; 
    padding:5px;
    background-color:white;

}
.slider .indicators .indicator-item{
    background-color: red;
}


.contactUs{
    position: fixed;
    right: 30px;
    bottom: 30px;
}

.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-primary { color: rgba(239, 65, 35, 1); font-size: 250px;  }
.material-icons.md-divider { color: rgba(182, 182, 182, 0.8); }
.material-icons.md-100 { font-size: 100px; }
.material-icons.md-40 { font-size: 40px; }
