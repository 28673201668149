#ModalAtleteBack{
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  z-index: 10000;
  background-color: black;
  opacity: 0.7;

}
.sizeModal{
  height: 100%;
  width: 95%;
}

#ModalAtlete{
  display: block;
  position: fixed;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  z-index: 10000;
  background-color: white;

}

.OpenAtlete {
  animation-name: OpenAtleteAnim;
  animation-duration: 0.5s;
}

.cerrarModal{
  position: absolute;
  top: 1%;
  right: 1%;
  margin-top: 10px;
  margin-right: 10px;

}
.padding{
  padding: 20px;
}
@keyframes OpenAtleteAnim {
  0%   {left:48%; top:40%; width: 4%; height: 20%;}
  70%  {left:5%; top:40%; width: 90%; height: 20%;}
  80%  {left:5%; top:55%; width: 90%; height: 20%;}
  100% {left:5%; top:5%; width: 90%; height: 90%;}
}
.overflowTrue{
  overflow-y: visible;
}

