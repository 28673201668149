body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
@font-face {
  font-family: "MavenProRegular";
  src: local("MavenProRegular"),
    url(./fonts/maven_pro_regular-webfont.ttf) format("opentype");
}
@font-face {
  font-family: "MavenProBold";
  src: local("MavenProBold"), url(./fonts/MavenProBold.ttf) format("opentype");
}
@font-face {
  font-family: "Forza";
  src: local("Forza"), url(./fonts/Forza-Bold.otf) format("opentype");
}
@font-face {
  font-family: "Redig";
  src: local("Redig"), url(./fonts/Redig-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Digital";
  src: local("Digital"), url(./fonts/digital-7Final.ttf) format("opentype");
}
@font-face {
  font-family: "BebasNeue-Regular";
  src: url("./fonts/BebasNeue-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "BabaPro-Bold";
  src: url("./fonts/BabaPro-Bold.ttf") format("opentype");
}
