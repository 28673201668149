.ModalAtleteBack{
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  z-index: 101;
  background-color: black;
  opacity: 0.7;

}

.ModalAtleteSmall{
  display: block;
  position: fixed;
  z-index: 101;
  background-color: white;
  width: 40%;
  height: 50%;
  left: 30%;
  top: 20%;
}
.ModalAtleteBig{
  display: block;
  position: fixed;
  z-index: 101;
  background-color: white;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
}

.OpenAtleteSmall {
  animation-name: OpenAtleteAnimSmall;
  animation-duration: 0.2s;
}
.OpenAtleteBig {
  animation-name: OpenAtleteAnimBig;
  animation-duration: 0.5s;
}

.cerrarModal{
  position: absolute;
  top: 1%;
  right: 1%;
  margin-top: 10px;
  margin-right: 10px;

}
.padding{
  padding: 20px;
}
@keyframes OpenAtleteAnimBig {
  0%   {left:48%; top:40%; width: 4%; height: 20%;}
  70%  {left:5%; top:40%; width: 90%; height: 20%;}
  80%  {left:5%; top:55%; width: 90%; height: 20%;}
  100% {left:5%; top:5%; width: 90%; height: 90%;}
}
@keyframes OpenAtleteAnimSmall {
  0%   {left:48%; top:40%; width: 4%; height: 20%;}
  70%  {left:30%; top:40%; width: 40%; height: 20%;}
  80%  {left:30%; top:55%; width: 40%; height: 20%;}
  100% {left:30%; top:20%; width: 40%; height: 40%;}
}

@media only screen  
and (max-width : 600px) {
/* Styles */


.ModalAtleteSmall{
  display: block;
  position: fixed;
  z-index: 101;
  background-color: white;
  width: 98%;
  height: 60%;
  left: 1%;
  top: 20%;
}
.ModalAtleteBig{
  display: block;
  position: fixed;
  z-index: 101;
  background-color: white;
  width: 98%;
  height: 98%;
  left: 1%;
  top: 1%;
}

@keyframes OpenAtleteAnimBig {
  0%   {left:48%; top:40%; width: 4%; height: 20%;}
  70%  {left:1%; top:40%; width: 98%; height: 20%;}
  80%  {left:1%; top:55%; width: 98%; height: 20%;}
  100% {left:1%; top:1%; width: 98%; height: 98%;}
}
@keyframes OpenAtleteAnimSmall {
  0%   {left:48%; top:40%; width: 4%; height: 20%;}
  70%  {left:1%; top:40%; width: 98%; height: 20%;}
  80%  {left:1%; top:55%; width: 98%; height: 20%;}
  100% {left:1%; top:20%; width: 98%; height: 60%;}
}

}


/* iPads ----------- */
@media only screen 
and (min-width : 600px) 
and (max-width : 992px) 
{
/* Styles */

.ModalAtleteSmall{
  display: block;
  position: fixed;
  z-index: 101;
  background-color: white;
  width: 60%;
  height: 60%;
  left: 20%;
  top: 20%;
}
.ModalAtleteBig{
  display: block;
  position: fixed;
  z-index: 101;
  background-color: white;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
}

@keyframes OpenAtleteAnimBig {
  0%   {left:48%; top:40%; width: 4%; height: 20%;}
  70%  {left:5%; top:40%; width: 90%; height: 20%;}
  80%  {left:5%; top:55%; width: 90%; height: 20%;}
  100% {left:5%; top:5%; width: 90%; height: 90%;}
}
@keyframes OpenAtleteAnimSmall {
  0%   {left:48%; top:40%; width: 4%; height: 20%;}
  70%  {left:20%; top:40%; width: 60%; height: 20%;}
  80%  {left:20%; top:55%; width: 60%; height: 20%;}
  100% {left:20%; top:20%; width: 60%; height: 60%;}
}


}

