#modalWhiteContainer{
  top: 0%;
  height: 100%;
  background-color: white;
  padding: 0%;
  margin: 0%;
}
.ContTabla{
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: white;
    max-height: 100%;
    overflow-y: auto;
  
  }
  #Tabla{
    position: absolute;
    top: 65px;
    z-index: 101;
    background-color: white;
    min-height: 90%;
    width: 99.8%;
    overflow-x: auto;
  
  }
  #Tabla-1{
    background-color: white;
  }
  #Tabla table{
    z-index: 150;
    background-color: transparent;
    max-width: 99.5%;
  
  }
  
  .AbrirTabla {
    animation-name: AbrirTablaAnim;
    animation-duration: 2s;
  }
  .marginClose{
    margin-right: 30px;
  }
  .marginIcons{
    position: absolute;
    right: 80px;
    
  }
  
  .AbrirTablaIntra {
    animation-name: AbrirTablaAnim;
    animation-duration: 0.5s;
  }
  
  
  @keyframes AbrirTablaAnim {
    0%   {left:48%; top:40%; width: 4%; height: 20%;}
    70%  {left:0%; top:40%; width: 100%; height: 20%;}
    80%  {left:0%; top:55%; width: 100%; height: 20%;}
    100% {left:0%; top:0%; width: 100%; height: 100%;background-color: white;}
}

  